
@media screen and (max-width:1280px) {
    :root{
        --body-width:100%;
        --header-width:100%;
        --pading-video:0;
    }
    
}

@media screen and (min-width:1280px) {
    :root{
        --body-width: 1200px;
        --header-width: 1280px;
        --pading-video:40px;
    }
}
// @media screen and (max-width:1540px) {
//     :root{
//         --font-size-small:14px
//     }
// }
@media screen and (min-width:1540px) {
    :root{
        --font-size-small:14px
    }
}
:root{
    --el-header-height:60px;
}
$hoveColor:rgb(155,252,118);
$activeColor:rgb(127,205,97);
$bodyColor:rgb(28,109,239);
$footersColor:rgb(40,52,66);
$greyColor:rgba(209, 209, 209, 1);
$logoColor:rgb(38,53,84);
$redColor:rgb(74,17,6);
$cpbjColor:rgb(5,72,91);
*{
    font-family: Arial, Helvetica, sans-serif;
}